import React from 'react'

import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube'
import { useIntl } from 'gatsby-plugin-react-intl'

import {
    SharedStyles,
    Container,
    VideoWithInfo,
} from '../../kenra-storybook/index'

const { StSectionTitle } = SharedStyles

export default function ProductVideo({ video, title, hideTitle }) {
    let intl = useIntl()
    let youTubeId = getYouTubeId(video.youtubeLink)
    return (
        <section>
            {!hideTitle && <Container>
                <StSectionTitle styleBig>
                    <h2>
                        {(title && title.toUpperCase()) ||
                            intl.formatMessage({ id: 'product-video-how-to' })}
                    </h2>
                </StSectionTitle>
            </Container>}
            <Container useLargeWidth>
                <VideoWithInfo
                    img={getPreviewUrl(youTubeId)}
                    useImgOverlay
                    videoType="youtube"
                    autoPlay={true}
                    videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
                />
            </Container>
        </section>
    )
}
