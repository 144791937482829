import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';
import url from 'helpers/url';
import { sortByCreatedAt } from 'helpers/sort';
import TOPICS from 'helpers/blogTopics';
import { getBlogPostUrl } from 'helpers/blogUrl';
import { injectIntl } from 'gatsby-plugin-react-intl';
import { mapLocale } from 'helpers/mapLocale';

import { SharedStyles, Container, Articles } from '../../kenra-storybook/index';

const { StSectionTitle, StLink } = SharedStyles;

const BlogArticles = ({ intl }) => {
    const {
        allContentfulBlogPost: { nodes },
    } = useStaticQuery(graphql`
        query {
            allContentfulBlogPost {
                nodes {
                    ...ContentfulBlogPostFields
                }
            }
        }
    `);

    let localeToCheck = mapLocale(intl.locale);
    let storedRegion = '';
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region');
    }

    let localeArticles = nodes.filter(
        article => article.node_locale === localeToCheck
    );

    let articles = sortByCreatedAt(localeArticles)
        .filter(blog => {
            if (
                typeof blog.region === 'undefined' ||
                blog.region === null ||
                blog.region.length === 0 ||
                blog.region.includes(storedRegion)
            ) {
                return blog.archivedArticle ? false : true;
            } else {
                return false;
            }
        })
        .slice(0, 2);

    return (
        <section>
            <StSectionTitle>
                <Container>
                    <h2>{intl.formatMessage({ id: 'blog-articles-title' })}</h2>
                    <StLink mobileHide>
                        <a href={url.blog}>
                            {intl.formatMessage({ id: 'view-all-text' })} (
                            {nodes.length})
                        </a>
                    </StLink>
                </Container>
            </StSectionTitle>
            <Articles
                items={articles.map(item => ({
                    bg: get(item, 'image.localFile.publicURL'),
                    type: item.topic,
                    typeColor: get(TOPICS, `${item.topic}.color`),
                    title: item.title,
                    subtitle: `BY ${item.author}`,
                    // date: `posted ${format(parseISO(item.createdAt), 'd.MM.yyyy')}`,
                    linkText: intl.formatMessage({
                        id: 'blog-articles-read-post',
                    }),
                    linkHref: getBlogPostUrl(item),
                }))}
            />
        </section>
    );
};

export default injectIntl(BlogArticles);
