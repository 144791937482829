import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import BlogPost from 'components/BlogPost';
import SEO from 'components/seo';
import get from 'lodash.get';

export default props => {
    let { contentfulBlogPost } = props.data;
    if (!contentfulBlogPost) return 'No blog post';

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulBlogPost,
                    'seoListing.description.description'
                )}
                title={get(contentfulBlogPost, 'seoListing.seoTitle')}
                titleTemplate={'%s'}
                slug={`blog/${contentfulBlogPost.url}`}
            />
            <BlogPost page={contentfulBlogPost} />
        </Layout>
    );
};

export const query = graphql`
    query($contentful_id: String!, $locale: String) {
        contentfulBlogPost(
            contentful_id: { eq: $contentful_id }
            node_locale: { eq: $locale }
        ) {
            ...ContentfulBlogPostFields
        }
    }
`;
